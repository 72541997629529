// Copyright 1999-2020. Plesk International GmbH. All rights reserved.

/* eslint-disable react/jsx-max-depth */

import { createElement, useState } from 'react';
import PropTypes from 'prop-types';
import JswComponent from 'plesk/components/JswComponent';
import { Layout } from '@plesk/ui-library';
import Cookie from 'common/cookie';
import LoginMobileApplicationPromotion from './LoginMobileApplicationPromotion';
import Brand from './Brand';
import Status from './Status';
import CookiePolicy from './CookiePolicy';
import { useDataContext } from './DataContext';

const MOBILE_APPLICATION_PROMOTION_COOKIE_NAME = 'mobile-application-promotion-hidden';

const LoginLayout = ({ mobileApplicationPromotion, extensionsAdditionalBody, status, isCookiePolicyInfoEnabled, children }) => {
    const { data: { logo } } = useDataContext();

    const [
        isMobileApplicationPromotionVisible,
        setMobileApplicationPromotionVisible,
    ] = useState(Boolean(mobileApplicationPromotion && Cookie.get(MOBILE_APPLICATION_PROMOTION_COOKIE_NAME) !== '1'));

    const handleMobileApplicationPromotionHide = () => {
        setMobileApplicationPromotionVisible(false);
        Cookie.setPermanent(MOBILE_APPLICATION_PROMOTION_COOKIE_NAME, '1', '/');
    };

    return (
        <div className="login-page">
            <CookiePolicy layoutType="simplified" isInfoEnabled={isCookiePolicyInfoEnabled} />
            <Layout
                type="simplified"
                className="login-page__inner"
                header={(
                    logo ? (
                        <div className="page-simplified-header">
                            <div className="page-simplified-header__brand">
                                <Brand {...logo} />
                            </div>
                        </div>
                    ) : null
                )}
                contentAddon={(
                    isMobileApplicationPromotionVisible ? (
                        <LoginMobileApplicationPromotion {...mobileApplicationPromotion} onHide={handleMobileApplicationPromotionHide} />
                    ) : null
                )}
            >
                <div className="login-page__content">
                    <Status messages={status} />
                    {children}
                    {extensionsAdditionalBody ? (
                        <JswComponent>{extensionsAdditionalBody}</JswComponent>
                    ) : null}
                </div>
            </Layout>
            <div id="bottomAnchor" style={{ position: 'fixed', top: 0, zIndex: -1, opacity: 0.001 }}>{Date.now()}</div>
        </div>
    );
};

LoginLayout.propTypes = {
    isCookiePolicyInfoEnabled: PropTypes.bool,
    mobileApplicationPromotion: PropTypes.object,
    extensionsAdditionalBody: PropTypes.string,
    status: PropTypes.array,
    children: PropTypes.any,
};

LoginLayout.defaultProps = {
    isCookiePolicyInfoEnabled: false,
    mobileApplicationPromotion: undefined,
    extensionsAdditionalBody: undefined,
    status: [],
    children: undefined,
};

export const withLoginLayout = Component => {
    // eslint-disable-next-line react/prop-types
    const Wrapper = ({ mobileApplicationPromotion, extensionsAdditionalBody, status, isCookiePolicyInfoEnabled, isCookiePolicyPreferencesEntrypointEnabled, ...props }) => (
        <LoginLayout
            mobileApplicationPromotion={mobileApplicationPromotion}
            extensionsAdditionalBody={extensionsAdditionalBody}
            status={status}
            isCookiePolicyInfoEnabled={isCookiePolicyInfoEnabled}
        >
            <Component isCookiePolicyPreferencesEntrypointEnabled={isCookiePolicyPreferencesEntrypointEnabled} {...props} />
        </LoginLayout>
    );
    Wrapper.displayName = `withLoginLayout(${Component.displayName || Component.name || 'Component'})`;
    Wrapper.WrappedComponent = Component;

    return Wrapper;
};

export default LoginLayout;
