// Copyright 1999-2017. Plesk International GmbH. All rights reserved.

/**
 * @class Locale
 */
export const Locale = class {
    constructor(messages) {
        this.messages = messages || {};
    }

    /**
     * Get locale message by key.
     * @param {String} key
     * @param {Object} [params]
     * @returns {String}
     */
    lmsg(key, params) {
        const value = this.messages[key];
        if ('undefined' === typeof value) {
            return `[${key}]`;
        }

        if ('undefined' === typeof params) {
            return value;
        }

        return Object.keys(params)
            .reduce((value, paramKey) => value.replace(`%%${paramKey}%%`, params[paramKey]), value);
    }
};

Locale.sections = {};

/**
 * Register new locale section
 * @param {String} name
 * @param {Object} [messages]
 * @static
 */
Locale.addSection = function (name, messages) {
    this.sections[name] = new Locale(messages);
};

/**
 * Returns registered locale section or created empty section
 * @param {String} name
 * @returns {Locale}
 * @static
 */
Locale.getSection = function (name) {
    if (!this.sections[name]) {
        this.sections[name] = new Locale();
    }
    return this.sections[name];
};
