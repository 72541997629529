// Copyright 1999-2020. Plesk International GmbH. All rights reserved.

import { createElement, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Switch as BaseSwitch, Route } from 'react-router-dom';

export const Switch = ({ children }) => {
    const HtmlPage = () => {
        useEffect(() => () => {
            document.getElementById('main').innerHTML = '';
        }, []);

        return null;
    };

    return (
        <BaseSwitch>
            {children}
            <Route component={HtmlPage} />
        </BaseSwitch>
    );
};

Switch.propTypes = {
    children: PropTypes.node.isRequired,
};

export default Switch;
