// Copyright 1999-2019. Plesk International GmbH. All rights reserved.

import { Popup } from './popup';
import { Locale } from './locale';
import escapeHtml from './escapeHtml';

export const ScheduledTasks = {};
ScheduledTasks.showOutputPopup = (event, link) => {
    event.preventDefault();

    const config = JSON.parse(link.getAttribute('data-config'));
    const output = link.getAttribute('data-output');
    const locale = new Locale(config.locale);

    let titleMessage = '';
    let titleClass = '';
    if (config.result) {
        titleMessage = 'popupDone';
        titleClass = 'hint-ok';
    } else {
        titleMessage = 'popupError';
        titleClass = 'hint-failed';
    }
    titleMessage = locale.lmsg(titleMessage, {
        task: config.name,
        seconds: config.seconds,
    });

    const popup = new Popup({
        id: 'scheduled-tasks-popup',
        title: locale.lmsg('popupTitle'),
        content: (
            `<div><p><span class="${titleClass}"><b>${titleMessage}</b></span></p></div>` +
            '<div class="tree-box" style="height: 400px">' +
                `<p>${escapeHtml(output).replace(/\n/gm, '<br>')}</p>` +
            '</div>'
        ),
        buttons: [{
            title: locale.lmsg('popupClose'),
            handler() {
                popup.hide();
            },
        }],
    });
};
